const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../components/error.svelte"),
	() => import("../../../src/routes/index.svelte"),
	() => import("../../../src/routes/user-plans/__layout.svelte"),
	() => import("../../../src/routes/user-plans/index.svelte"),
	() => import("../../../src/routes/user-plan/__layout.svelte"),
	() => import("../../../src/routes/user-plan/index.svelte"),
	() => import("../../../src/routes/user-plan/[id].svelte"),
	() => import("../../../src/routes/new-plan/__layout.svelte"),
	() => import("../../../src/routes/new-plan/index.svelte"),
	() => import("../../../src/routes/login/index.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]],

	// src/routes/user-plans/index.svelte
	[/^\/user-plans\/?$/, [c[0], c[3], c[4]], [c[1]]],

	// src/routes/user-plan/index.svelte
	[/^\/user-plan\/?$/, [c[0], c[5], c[6]], [c[1]]],

	// src/routes/user-plan/[id].svelte
	[/^\/user-plan\/([^/]+?)\/?$/, [c[0], c[5], c[7]], [c[1]], (m) => ({ id: d(m[1])})],

	// src/routes/new-plan/index.svelte
	[/^\/new-plan\/?$/, [c[0], c[8], c[9]], [c[1]]],

	// src/routes/login/index.svelte
	[/^\/login\/?$/, [c[0], c[10]], [c[1]]]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];